import { APIError } from './errors';

/**
 * Reconstructs an APIError from a serialized error object if needed,
 * or returns the original error if it's already an APIError or another type
 */
export function checkError(error: unknown): APIError | Error {
  // If it's already an APIError instance, return it
  if (error instanceof APIError) {
    return error;
  }

  // Check if it's a serialized APIError
  if (
    error &&
    typeof error === 'object' &&
    'code' in error &&
    'statusCode' in error &&
    'path' in error
  ) {
    return new APIError({
      code: (error as any).code,
      message: (error as any).message || 'Unknown error',
      statusCode: (error as any).statusCode,
      path: (error as any).path,
      details: (error as any).details,
    });
  }

  // If it's any other Error instance, return it
  if (error instanceof Error) {
    return error;
  }

  // For unknown error types, create a generic error
  return new Error(
    typeof error === 'string' ? error : 'An unknown error occurred'
  );
}